import { faGear, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Alert, Badge, Button, Form, InputGroup, Table } from "react-bootstrap";

import ReactMarkdown from "react-markdown";
import { itemDetail } from "../utils/erpQueries";
import { Batch } from "../utils/itemBatchReducer";

import BatchSelector from "./SalesOrderItemBatchSelector";

interface SalesOrderItemProps {
  item: {
    item_code: string;
    item_name: string;
    required_qty?: number;
    completed?: boolean;
    qty?: number;
    uom: string;
    sample?: boolean;
    billedQty?: number;
    conversion_factor?: number;
  };
  itemDetail?: itemDetail;
  batches?: {
    name: string;
    qty: number;
    uom: string;
  }[];
  reservedBatches: {
    batch_no: string;
    stock_qty: number;
  }[];
  onBatchChange?: (batch: Batch[]) => void;
  selectedBatches?: Batch[];
  batchChoiceDisabled?: boolean;
  notModifiable?: boolean;
  lessAllowed?: boolean;
  moreAllowed?: boolean;
}
export const SalesOrderItem = (props: SalesOrderItemProps) => {
  const [showSettings, setShowSettings] = React.useState(false);

  const setBatchAndQty = (qty: number, batch: string, index: number) => {
    if (props.onBatchChange && props.selectedBatches) {
      let currentBatches = props.selectedBatches;
      currentBatches[index] = { qty, batch, item: props.item.item_code };
      props.onBatchChange(currentBatches);
    }
  };

  const addBatch = () => {
    if (props.onBatchChange && props.selectedBatches) {
      let currentBatches = props.selectedBatches;
      currentBatches.push({ qty: 0, batch: "", item: props.item.item_code });
      props.onBatchChange(currentBatches);
    }
  };

  const removeBatch = (index: number) => {
    if (props.onBatchChange && props.selectedBatches) {
      let currentBatches = [...props.selectedBatches];
      currentBatches.splice(index, 1);
      props.onBatchChange(currentBatches);
    }
  };

  // When an invoice is created the item may be deactivated.
  React.useEffect(() => {
    if (props.batchChoiceDisabled) setShowSettings(false);
  }, [props.batchChoiceDisabled]);

  let cellStyle = showSettings ? { border: "none" } : {};

  return (
    <React.Fragment key={props.item.item_code}>
      <tr>
        <td style={cellStyle}>
          <span className={`${props.item.completed ? "text-success" : ""}`}>
            {props.item.qty?.toString().replace(".", ",")}{" "}
          </span>
          <span>{props.item.required_qty?.toString().replace(".", ",")} </span>
          <span className={`${props.item.completed ? "text-success" : ""}`}>
            {props.item.uom}
          </span>
          {!!props.item.qty &&
            !!props.item.billedQty &&
            props.item.billedQty > 0 && (
              <span className="text-danger">
                {" "}
                (
                {(props.item.qty - props.item.billedQty)
                  .toString()
                  .replace(".", ",")}{" "}
                {props.item.uom} offen)
              </span>
            )}
        </td>
        <td
          style={{
            ...cellStyle,
            color: props.itemDetail?.warehouse_color
              ? props.itemDetail.warehouse_color
              : "#000",
          }}
        >
          {!props.itemDetail?.warehouse_title && props.item.item_name}
          {props.itemDetail?.warehouse_title && (
            <ReactMarkdown
              components={{
                p: React.Fragment,
              }}
            >
              {props.itemDetail.warehouse_title}
            </ReactMarkdown>
          )}
          {!!props.item.sample && <Badge bg="success">Muster</Badge>}
        </td>
        <td align="right" style={cellStyle}>
          {props.batches && props.selectedBatches && !showSettings && (
            <BatchSelector
              selectedBatch={props.selectedBatches[0]}
              requestedQty={
                props.selectedBatches[0].qty *
                (props.item.conversion_factor ?? 1)
              }
              batchChoiceDisabled={props.batchChoiceDisabled}
              reservedBatches={props.reservedBatches}
              onBatchChange={(batch) =>
                setBatchAndQty(props.selectedBatches[0].qty, batch, 0)
              }
              batches={props.batches}
            />
          )}
        </td>
        <td style={{ ...cellStyle, padding: 0 }}>
          {!!props.onBatchChange &&
            !props.batchChoiceDisabled &&
            !props.notModifiable && (
              <Button
                className="rounded-circle mt-1"
                onClick={async () => {
                  setShowSettings(true);
                }}
              >
                <FontAwesomeIcon icon={faGear} />
              </Button>
            )}
        </td>
      </tr>
      {showSettings && (
        <tr>
          <td colSpan={4}>
            <h3 style={{ marginBottom: 0 }}>Chargensplit oder Teillieferung</h3>
            <Table>
              <thead>
                <tr>
                  <th>Menge</th>
                  <th>Charge</th>
                </tr>
              </thead>
              <tbody>
                {props.selectedBatches &&
                  props.selectedBatches.map((selectedBatch, index) => (
                    <tr key={index}>
                      <td>
                        <InputGroup
                          size="sm"
                          className="mt-1"
                          style={{ width: "auto" }}
                        >
                          <Form.Control
                            value={selectedBatch.qty}
                            type="number"
                            onChange={(event) =>
                              setBatchAndQty(
                                parseFloat(event.target.value) > 0
                                  ? parseFloat(event.target.value)
                                  : 0,
                                selectedBatch.batch,
                                index
                              )
                            }
                          />
                          <InputGroup.Text>
                            <small>{props.item.uom}</small>
                          </InputGroup.Text>
                        </InputGroup>
                      </td>
                      <td>
                        {props.batches && (
                          <BatchSelector
                            selectedBatch={selectedBatch}
                            requestedQty={
                              selectedBatch.qty *
                              (props.item.conversion_factor ?? 1)
                            }
                            reservedBatches={props.reservedBatches}
                            onBatchChange={(batch) =>
                              setBatchAndQty(selectedBatch.qty, batch, index)
                            }
                            batches={props.batches}
                          />
                        )}
                      </td>
                      <td>
                        {index > 0 && (
                          <Button
                            onClick={() => removeBatch(index)}
                            className="rounded-circle"
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              onClick={() => removeBatch(index)}
                            />
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                {props.batches && (
                  <tr>
                    <td
                      colSpan={3}
                      style={{
                        textAlign: "center",
                        borderBottom: "none",
                        padding: 0,
                      }}
                    >
                      <Button
                        onClick={addBatch}
                        className="rounded-circle mt-2"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <strong>Summe:</strong>{" "}
            {props.selectedBatches
              .reduce((acc, curr) => acc + curr.qty, 0)
              .toFixed(3)
              .replace(".", ",")}{" "}
            {props.item.uom}
            {props.selectedBatches.reduce((acc, curr) => acc + curr.qty, 0) -
              props.item.qty >
              0.001 ||
              (props.selectedBatches.reduce((acc, curr) => acc + curr.qty, 0) -
                props.item.required_qty >
                0.001 && (
                <Alert variant="danger">Menge größer als bestellt!</Alert>
              ))}
            {props.selectedBatches.reduce((acc, curr) => acc + curr.qty, 0) -
              props.item.qty <
              -0.001 ||
              (props.selectedBatches.reduce((acc, curr) => acc + curr.qty, 0) -
                props.item.required_qty <
                -0.001 && (
                <Alert variant="info">
                  Menge geringer als bestellt! Teillieferung!
                </Alert>
              ))}
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};
